var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          ref: "interval-management-form",
          attrs: {
            id: "interval-management-form",
            submitAction: _vm.isEdit ? _vm.update : _vm.create,
            submitSuccessAction: _vm.isEdit
              ? _vm.updateSuccess
              : _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "rangesTypes",
                  placeholder: _vm.$t("intervalManagement.rangesTypes"),
                  dataSource: _vm.rangesTypes,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.data.type,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "type", $$v)
                  },
                  expression: "data.type"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "form-group",
              staticStyle: { "margin-top": "10px" }
            },
            [
              _c("table", [
                _c("colgroup", [
                  _c("col", { attrs: { width: "160px" } }),
                  _c("col", { attrs: { width: "8px" } }),
                  _c("col", { attrs: { width: "160px" } }),
                  _c("col", { attrs: { width: "16px" } }),
                  _c("col", { attrs: { width: "160px" } }),
                  _c("col", { attrs: { width: "16px" } })
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.data.values, function(item, index) {
                    return _c("tr", { key: item.id }, [
                      _c(
                        "td",
                        [
                          _c("app-numerictextbox", {
                            attrs: {
                              id: `initialValue_${item.id}`,
                              placeholder: _vm.$t(
                                "intervalManagement.initialValue"
                              ),
                              format: _vm.typeFormat
                            },
                            model: {
                              value: item.initialValue,
                              callback: function($$v) {
                                _vm.$set(item, "initialValue", $$v)
                              },
                              expression: "item.initialValue"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", { staticStyle: { "padding-top": "28px" } }, [
                        _vm._v("-")
                      ]),
                      _c(
                        "td",
                        [
                          _c("app-numerictextbox", {
                            attrs: {
                              id: `finalValue_${item.id}`,
                              placeholder: _vm.$t(
                                "intervalManagement.finalValue"
                              ),
                              format: _vm.typeFormat
                            },
                            model: {
                              value: item.finalValue,
                              callback: function($$v) {
                                _vm.$set(item, "finalValue", $$v)
                              },
                              expression: "item.finalValue"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td"),
                      _c(
                        "td",
                        [
                          _c("app-colorpicker", {
                            attrs: {
                              id: `color_${item.id}`,
                              label: _vm.$t("intervalManagement.color")
                            },
                            model: {
                              value: item.color,
                              callback: function($$v) {
                                _vm.$set(item, "color", $$v)
                              },
                              expression: "item.color"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td"),
                      _c(
                        "td",
                        { staticStyle: { "padding-top": "28px" } },
                        [
                          index > 0
                            ? _c("app-button", {
                                attrs: {
                                  type: "button",
                                  iconCss: "e-delete e-icons",
                                  title: _vm.$t("intervalManagement.remove")
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.removeInterval(item.id)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "padding-top": "28px" } },
                        [
                          index == _vm.data.values.length - 1
                            ? _c("app-button", {
                                attrs: {
                                  type: "button",
                                  iconCss: "e-add e-icons",
                                  title: _vm.$t("intervalManagement.add")
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.addInterval(item.finalValue)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ])
            ]
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: _vm.isEdit ? "shared.edit" : "shared.add",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }